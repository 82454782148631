import { IDocumentsDTO } from "../model/DocumentsDTO.model"
import { IFormDocument } from "../model/FormDocument.model"


const apiUrl = process.env.REACT_APP_API_URL + '/fileservice'


export const downloadDocument = (uuid: string): Promise<any> => {

    return fetch(`${apiUrl}/document/${uuid}/download`,
        {
            method: "GET",
        })
        .then(_ => _.blob())
}

export const deleteDocument = (uuid: string): Promise<any> => {

    return fetch(`${apiUrl}/document/${uuid}`,
        {
            method: "DELETE"
        })
}

export const downloadDocumentStatic = (codeOffer: string, type: string): Promise<any> => {

    return fetch(`${apiUrl}/document/static/${codeOffer}/${type}/download`,
        {
            method: "GET",
        })
        .then(_ => {
            if (_.status / 100 >= 4) throw _.json();
            return _.blob();
        })
}

export const getFormDocuments = (uuid: string): Promise<IFormDocument[]> => {

    return fetch(`${apiUrl}/form/${uuid}/documents`,
        {
            method: "GET",
        })
        .then(_ => _.json())
}

export const getFormDocument = (uuid: string, type: string): Promise<IFormDocument> => {

    return fetch(`${apiUrl}/form/${uuid}/document/${type}`,
        {
            method: "GET",
        })
        .then(_ => _.json())
}

export const uploadFormDocument = (formDocument: IFormDocument,
    formUuid: string,
    formData: FormData): Promise<IFormDocument> => {
    let uri: string = `${apiUrl}/form/${formUuid}/document/${formDocument.type}/upload`;

    if (formDocument.uuid)
        uri += `?docUuid=${formDocument.uuid}`

    return fetch(uri,
        {
            method: "POST",
            body: formData
        })
        .then(_ => {
            if (_.status === 200) return _.json()
            else throw _.json()
        })
}

export const generateFormDocuments = (uuid: string, documentsDTO: IDocumentsDTO): Promise<IFormDocument[]> => {

    return fetch(`${apiUrl}/form/${uuid}/documents`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(documentsDTO)
        })
        .then(_ => _.json())
}

export const generateFormDocumentsStatic = (uuid: string, codeOffer: string): Promise<IFormDocument[]> => {

    return fetch(`${apiUrl}/form/${uuid}/documents/static/${codeOffer}`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
        .then(_ => _.json())
}
