import React, { useEffect } from 'react';

import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { FormHeader } from '../../components/Form/FormHeader';
import { FormHeaderSection } from '../../components/Form/FormHeaderSection';
import { FormSignValidate } from '../../components/Form/FormSignValidate';

import { downloadDocumentStatic } from '../../api/FormDocument.api';

import { IContract } from '../../model/Contract.model';
import { IContractSignature } from '../../model/ContractSignature.model';
import { IPlateforme } from '../../model/Plateforme.model';
import { IPerson } from '../../model/Person.model';

import { enumFormDocumentTypeTG } from '../../utils/enums';
import { PageContentLayout } from '../../components/Layout/PageContentLayout';
import { FormPlateformCode } from '../../components/Form/FormPlateformCode';
import { useParams } from 'react-router-dom';


interface Props {
    plateformStyle: any;
    sidebar?: JSX.Element;
    codeOffer?: string;
    contractSignature: IContractSignature;
    contract: IContract;
    plateform: IPlateforme;
    subscriber: IPerson;
    updatePlateform?: () => void;
    sendContractSignature: (contractSignature: IContractSignature) => void;
    goToPreviousFlag: boolean;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormContractSignValidate = (props: Props) => {

    const urlFormUuid = useParams<{ uuid: string }>().uuid

    const plateformStyle: any = props.plateformStyle;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;

    const formRef = React.createRef<HTMLFormElement>();

    const sendContractSignature = (contractSignature: IContractSignature) => {
        props.sendContractSignature(contractSignature);  // Update Form states.
    }

    const handleClick = () => {
        if (!props.codeOffer)
            return;

        downloadDocumentStatic(props.codeOffer, enumFormDocumentTypeTG).then(response => {
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );

            link.href = url;
            link.setAttribute('download', props.codeOffer === '539' ? "GSMC - Tableau de garanties - Activ' Santé TNS.pdf" : props.codeOffer === '512' ? "GSMC - Tableau de garanties - Generation 100 pourcent nous.pdf" : "GSMC - Tableau de garanties - Seniors 100 pourcent nous.pdf");

            document.body.appendChild(link);

            link.click();
        })
            .catch(e => { });  // TODO;
    }


    useEffect(() => {
        if (!props.goToPreviousFlag)
            return;

        props.goToPrevious();
    }, [props.goToPreviousFlag])


    return (
        <PageContentLayout
            header={
                <FormHeader
                    label="Vérification du code"
                    description="Un SMS vous a été envoyé" />}
            sidebar={props.sidebar}
            content={
                <Card>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            p: 2
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <FormHeaderSection label="Vous avez reçu un code par SMS" />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormSignValidate
                                ref={formRef}
                                contractSignature={props.contractSignature}
                                contractId={props.contract.id ? props.contract.id : 0}
                                plateformName={props.plateform.name}
                                subscriberPhone={props.subscriber.phoneMobile ? props.subscriber.phoneMobile : ""}
                                send={sendContractSignature}
                                goToNext={props.goToNext}
                                goToPrevious={props.goToPrevious} />
                        </Grid>

                        {urlFormUuid && props.updatePlateform &&
                            <Grid
                                item
                                xs={12}
                                sm={6}>
                                <FormPlateformCode
                                    plateformStyle={props.plateformStyle}
                                    code={props.plateform?.code}
                                    uuid={urlFormUuid}
                                    send={() => props.updatePlateform?.()} />
                            </Grid>}
                    </Grid>
                </Card>
            }
            footer={
                <>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: {
                                md: 'justify',
                                xs: 'left'
                            },
                            mt: 3
                        }}>
                        <Box
                            sx={{
                                mx: 'auto'
                            }}>
                            <Typography
                                variant='body1'
                                fontWeight={500}>
                                En cliquant sur “Signer votre contrat”, je reconnais avoir pris connaissance du&nbsp;
                                <Link
                                    onClick={() => handleClick()}
                                    noWrap>
                                    règlement mutualiste
                                </Link>
                                &nbsp;notamment les conditions d'exercice de la faculté de renonciation ainsi que des mentions légales du site et vérifié l'exactitude des informations sur mon mandat SEPA.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        md={12}
                        sx={{
                            width: '100%',
                            textAlign: 'center'
                        }}>
                        <Button
                            className="Button_Goto_Step4_Confirmation"
                            variant='contained'
                            color={buttonSubmitStyle.color}
                            sx={{
                                width: {
                                    sm: 400,
                                    xs: '100%'
                                },
                                mt: 3,
                                mb: 5,
                                px: 5,
                                color: 'white'
                            }}
                            onClick={() => {
                                if (formRef.current)
                                    formRef.current.handleSubmit();
                            }} >
                            Signer votre contrat
                        </Button>
                    </Grid>
                </>
            } />
    )
}
